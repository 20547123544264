import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "deviations"
    }}>{`Deviations`}</h1>
    <p>{`The application Ukur detects and enables subscriptions for deviations in traffic based on real-time information from the application Anshar.`}</p>
    <ul>
      <li parentName="ul">{`BASE_URL: `}<a parentName="li" {...{
          "href": "https://api.entur.io/realtime-deviations/v1/subscription"
        }}>{`https://api.entur.io/realtime-deviations/v1/subscription`}</a></li>
    </ul>
    <h2 {...{
      "id": "subscriptions"
    }}>{`Subscriptions`}</h2>
    <p>{`There are two ways to create and maintain subscriptions in Ukur: an original JSON way and the standardized SIRI format with XML. The latter does not support subscriptions on to- and from-stops, but otherwise they are quite similar. Ukur is not intended to push messages directly to end-user clients, instead it is expected that the subscriber handles that with knowledge about what to push, how often etc. Also the subscriber should make sure that similar subscriptions to end-users result in only one subscription to Ukur.`}</p>
    <h3 {...{
      "id": "proprietary-json-format"
    }}>{`Proprietary JSON format`}</h3>
    <p>{`To subscribe, post subscription data to `}<inlineCode parentName="p">{`https://{BASE_URL}/subscription`}</inlineCode>{` as application/json.`}</p>
    <p>{`The subscription must contain a logical name, a public reachable push address and what is subscribed upon: from and to stops and/or lines and/or codespaces.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{` Default value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Valid values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A logical name to describe the subscription.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pushAddress`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The address to which deviation messages for this subscription should be sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"ALL"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"ALL"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"ET"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"SX"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The source of deviation messages you are interested in.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fromStopPoints`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of strings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of StopPoints to filter deviations from.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`toStopPoints`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of strings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of StopPoints to filter deviations to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lineRefs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of strings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of lineRefs to filter on.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`codeSpaces`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of strings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of codeSpaces to filter on.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`useSiriSubscriptionModel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether notifications should be wrapped in a Siri root or not.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`initialTerminationTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode>{` (meaning never)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO8601 Timestamp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An ISO8601 timestamp string for when the subscription should be deleted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`heartbeatInterval`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode>{` (meaning never)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO8601 Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Period for heartbeats according to the Duration format from the W3C XML Schema 1.0 at which heartbeats (empty Siri/HeartbeatNotification xml messages) should be sent to the push address, default is null (no heartbeats). The interval is handled approximately, and notifications can come several seconds later than what is specified (depending of how often we configure Ukur to handle heartbeats)...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pushAllData`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can be set to true if all realtime messages matching the subscription should be pushed, and not just those with deviations.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`minimumDelay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"PT15M"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO8601 Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The minimum delay required for sending a message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`deviationType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"DELAYED"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"DELAYED"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"TRACK_CHANGE"`}</inlineCode>{`, `}<inlineCode parentName="td">{`"CANCELED"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of deviation messages you are interested in.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "example"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "name": "Test subscription",
    "pushAddress": "https://myserver/push",
    "type": "ALL",
    "fromStopPoints": ["NSR:Quay:551", "NSR:Quay:553", "NSR:Quay:550"],
    "toStopPoints": ["NSR:Quay:695", "NSR:Quay:696"],
    "lineRefs": ["NSB:Line:L14", "NSB:Line:R11"],
    "codespaces": ["NSB", "RUT"],
    "useSiriSubscriptionModel": false,
    "initialTerminationTime": "9999-01-01T00:00:00+01:00",
    "heartbeatInterval": "PT15M",
    "pushAllData": false,
    "minimumDelay": "PT15M",
    "deviationType": "DELAYED"
}
`}</code></pre>
    <p>{`After successful creation of the new subscription, Ukur responds with the same object with the ID-attribute set. This ID can be used to remove the subscription by issuing a http delete to `}<inlineCode parentName="p">{`https://{BASE_URL}/subscription/{subscriptionId}`}</inlineCode>{`.
Update is supported: Just post a subscription with the proper ID and desired new state to `}<inlineCode parentName="p">{`https://{BASE_URL}/subscription/`}</inlineCode>{`.`}</p>
    <p>{`StopPoints are fully qualified national IDs on stop places and quays. Use `}<a parentName="p" {...{
        "href": "https://stoppested.entur.org"
      }}>{`Stoppestedsregisteret`}</a>{` to look them up. The SIRI messages received from Anshar uses both stop places and quays to identify affected stops, Ukur maps stop places to quays so the subscription does not have to specify quays unless only certain quays are interesting. Stops not following the national ID format are ignored (as they never will be referenced). Also both from and to StopPoints must be present to receive push messages.`}</p>
    <p>{`Continue reading on `}<a parentName="p" {...{
        "href": "https://github.com/entur/ukur/blob/master/functional_description.md"
      }}>{`https://github.com/entur/ukur/blob/master/functional_description.md`}</a></p>
    <h2 {...{
      "id": "source-code-github"
    }}>{`Source code Github`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/entur/ukur"
        }}>{`https://github.com/entur/ukur`}</a></li>
    </ul>
    <h1 {...{
      "id": "authentication"
    }}>{`Authentication`}</h1>
    <p>{`This API is open under NLOD licence, however, it is required that all consumers identify themselves by using the header ET-Client-Name. Entur will deploy strict rate-limiting policies on API-consumers who do not identify with a header and reserves the right to block unidentified consumers. The structure of ET-Client-Name should be: "company-application"`}</p>
    <p>{`Header examples:`}</p>
    <ul>
      <li parentName="ul">{`"brakar-journeyplanner"`}</li>
      <li parentName="ul">{`"fosen_utvikling-departureboard"`}</li>
      <li parentName="ul">{`"nor_way_bussekspress-nwy-app"`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      